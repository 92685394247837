import * as AWS from 'aws-sdk'
import { BaseResponse } from '../types/global'
import { HTTPBaseService } from '@/utils/request'
import { FetchHotelType } from '@/types/hotel'
import store from '@/store'
import { getUser } from '@/utils/auth'

export default class Hotel extends HTTPBaseService {
  constructor(service = 'station-handler') {
    super(service)
  }

  /**
   * sfs-api
   */
  public async fetchListHotel(body: FetchHotelType): Promise<BaseResponse> {
    const { data } = await this.instance.post('mobile/hotels/list', { data: { ...body } })
    return data
  }

  /**
   * sfs-api
   */
  public async bookHotel(body: any): Promise<BaseResponse> {
    delete body.hotel_list_page
    if (process.env.VUE_APP_SENTRY_ENVIRONMENT == 'local') {
      const { data } = await this.instance.post('mobile/hotels/book', { data: { ...body } })
      return data
    } else {
      return await this.executeBookingByLambda({ data: { ...body } })
    }
  }

  /**
   * sfs-api
   */
  public async fetchMatrixHotels(body: any): Promise<BaseResponse> {
    const { data } = await this.instance.post('mobile/hotels/check-room-matrix', { data: { ...body } })
    return data
  }

  /**
   * sfs-api
   */
  public async executeBookingByLambda(body: any): Promise<BaseResponse> {
    store.dispatch('incrementLoading')
    const user = getUser()
    const _param = { data: { stationUserId: user.sub } }
    const dataEnv = (await this.instance.post('mobile/lambda-access-key', { data: { ..._param } })).data
    body.data.stationUserId = user.sub
    AWS.config.region = 'eu-central-1'
    AWS.config.credentials = new AWS.Credentials(dataEnv.data.access_key, dataEnv.data.secret_key)
    const lambda = new AWS.Lambda()
    const params = {
      FunctionName: dataEnv.data.lambda_funs,
      Payload: JSON.stringify({
        jobName: 'mobileIssueHotelJob',
        data: body
      }),
      InvocationType: 'RequestResponse'
    }
    const lambdaResult = await lambda.invoke(params).promise()
    const resultObject = JSON.parse(lambdaResult.Payload)
    store.dispatch('decrementLoading')
    return resultObject.original
  }
}
